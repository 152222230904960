import {
  getCommonParamsItemLabel
  // getGlobalParamsListItemLabel
} from '@/utils/common'

export const contentTableConfig = {
  propList: [
    {
      prop: 'aid',
      label: 'param.aid'
    },
    // {
    //   prop: 'md5',
    //   label: 'param.md5'
    // },
    {
      prop: 'tenantName',
      label: 'file.tenant'
      // formatter: (row) => {
      //   return getGlobalParamsListItemLabel(row, 'tenantList', {
      //     isNeedPreFix: false
      //   })
      // }
    },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'status',
      label: 'general.status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      prop: 'options',
      label: 'param.param-operation',
      width: '380',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
  // showSelectColumn: true
}
