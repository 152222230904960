import {
  getCommonParamsOptions,
  getParamsOptionsAsync
} from '@/utils/common.js'

export const searchFormConfig = {
  formItems: [
    {
      field: 'aid',
      type: 'input',
      label: 'param.aid'
    },
    // {
    //   field: 'md5',
    //   type: 'input',
    //   label: 'param.md5'
    // },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'file.tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        props: {
          multiple: true
        }
      },
      options: [],
      handler: () => getParamsOptionsAsync('tenantList')
    },
    {
      field: 'status',
      type: 'select',
      label: 'general.status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status'),
      otherOptions: {}
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
